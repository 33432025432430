import { httpClient } from '../plugins/httpClient'

import type { IMarket, IIMarket } from '../models/Market'

import { lastSymbToLower } from '../utils/strings'
import { isSubCurrency, symbolToURLsymbol } from '../utils/currency'
import type { TCurrency } from '../models/Currency'

export interface IUpdateMarketPayload {
  market: Partial<IMarket>
  domain?: string
}

export function expandMarketList(items: IMarket[]) {
  return items.map<IIMarket>(item => {
    const [base_currency, quote_currency] = item.name.split('/') as [
      TCurrency,
      TCurrency
    ]

    return {
      ...item,
      base_currency: base_currency,
      quote_currency: quote_currency,
      base_currency_fmt: isSubCurrency(base_currency)
        ? lastSymbToLower(base_currency)
        : base_currency,
      quote_currency_fmt: isSubCurrency(quote_currency)
        ? lastSymbToLower(quote_currency)
        : quote_currency,
      base_symbol_url: symbolToURLsymbol(base_currency),
      quote_symbol_url: symbolToURLsymbol(quote_currency)
    }
  })
}

export default {
  async getPublicMarkets() {
    const result = await httpClient.get<IMarket[]>('/public/markets')

    return result.data
  },

  async getAdminMarkets(domain?: string) {
    const result = await httpClient.get<IMarket[]>('/admin/markets', {
      params: { domain }
    })

    return result.data
  },

  async getUserMarkets() {
    const result = await httpClient.get<IMarket[]>('/markets')

    return result.data
  },

  async updateMarket({ market, domain }: IUpdateMarketPayload) {
    const result = await httpClient.put(
      `/admin/markets/${market.id}`,
      {
        ...market,
        id: undefined,
        base_id: undefined,
        quote_id: undefined
      },
      { params: { domain } }
    )

    return result.data
  }
}
