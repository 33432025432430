<template>
  <AppLoader :is-showing="isLoadingFirstData" />
  <RouterView />

  <!--  AppModals-->
  <AppModals />

  <!--  AppNotifications-->
  <AppNotifications ref="notification" />
</template>

<script setup lang="ts">
import { RouterView } from 'vue-router'

import AppModals from '~common/components/AppModals.vue'
import AppNotifications from '~common/components/AppNotifications.vue'

import useExchangeRates from '~common/stores/exchangeRates'

import { useRegisterNotify } from '~common/composables/notify'
import AppLoader from '~common/components/AppLoader.vue'
import { useAuthStore } from '~common/stores/auth'
import { storeToRefs } from 'pinia'
import useGuardState from '~common/composables/useGuardState'
import useNewVersionNotify from '~common/composables/useNewVersionNotify'
import utc from 'dayjs/plugin/utc'

import 'virtual:pwa-register/vue'
import useFetchCurrencies from './composables/useFetchCurrencies'
import useFetchMarkets from './composables/useFetchMarkets'
import useUiSettingsStore from './common/src/stores/uiSettings'
import { initTheme } from './common/themes/utils'
import { useI18n } from '~/common/src/composables/useI18n'
import dayjs from 'dayjs'
import { watch } from 'vue'

defineOptions({
  name: 'App'
})

/* ------------------------------------------------------------------------------------------------------------------ */
dayjs.extend(utc)

const authStore = useAuthStore()

const { isLoadingFirstData, isAuthenticated } = storeToRefs(useAuthStore())

const { currentLocale, setLocale } = useI18n()

useGuardState()

useUiSettingsStore()

useExchangeRates()

useFetchCurrencies()

useFetchMarkets()

const { notification } = useRegisterNotify()

useNewVersionNotify('admin.Version.title', 'common.Version.btnTitleLoadNewVer')

watch(
  currentLocale,
  async val => {
    if (!val) return

    dayjs.locale(val)

    await setLocale(val)

    if (!isAuthenticated) return

    await authStore.setLocale(val)
  },
  { immediate: true }
)

initTheme()
</script>
