import { defineStore } from 'pinia'
import type { IExchangeIIMarket, IMarket } from '~common/models/Market'

import marketApi from '~common/api/market.api'
import useCurrencyStore from '~common/stores/currency'

import { computed, shallowRef } from 'vue'
import { expandMarketList } from '~common/api/market.api'

export default defineStore('market-admin', () => {
  const currencyStore = useCurrencyStore()

  const items = shallowRef<IMarket[]>([])

  const expandedItems = computed(() => {
    return expandMarketList(items.value)
  })

  const exchangeItems = computed(() => {
    return expandedItems.value.map<IExchangeIIMarket>(item => {
      const [base, quote] = item.name.split('/')

      const baseCurrencies = currencyStore.currencySymbolMap.get(base)
      const quoteCurrencies = currencyStore.currencySymbolMap.get(quote)

      return {
        ...item,
        currencyName: `${baseCurrencies?.name}/${quoteCurrencies?.name}`
      }
    })
  })

  async function fetch(domain?: string) {
    items.value = await marketApi.getAdminMarkets(domain)
  }

  function getExpandedMarketById(id: number | undefined | null) {
    return expandedItems.value.find(item => {
      return item.id === id
    })
  }

  return {
    items,
    expandedItems,
    fetch,
    exchangeItems,
    getExpandedMarketById
  }
})
