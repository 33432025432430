/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createRouter, createWebHistory } from 'vue-router'
import { beforeEach } from './guard'
// @ts-ignore
import { setupLayouts } from 'virtual:generated-layouts'
// @ts-ignore
import generatedRoutes from 'virtual:generated-pages'

const originalConsoleWarn = console.warn

console.warn = (message, ...messageArgs) => {
  if (message.includes('must have the exact same param named')) {
    return
  }

  originalConsoleWarn(message, ...messageArgs)
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  linkActiveClass: 'is-link-active',
  linkExactActiveClass: 'is-exact-active',
  routes: setupLayouts(generatedRoutes)
})

console.warn = originalConsoleWarn

router.beforeEach(beforeEach)

export default router
