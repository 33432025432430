import { watch } from 'vue'

import useMarketAdminStore from '~/stores/marketAdmin'

import useWhiteLabel from './useWhiteLabel'

export default () => {
  const { activeWhiteLabel } = useWhiteLabel()

  const marketAdminStore = useMarketAdminStore()

  watch(
    activeWhiteLabel,
    val => {
      marketAdminStore.fetch(val?.domain)
    },
    { immediate: true }
  )
}
