<template>
  <div class="w-full h-96 is-loading" />
</template>

<script setup lang="ts">
import { watch } from 'vue'
import { useRouter } from 'vue-router'
import useWhiteLabelStore from '~/stores/whiteLabel'

import useNavigationList from '~/composables/useNavigationList'

import { useAuthStore } from '~common/stores/auth'
import { storeToRefs } from 'pinia'

defineOptions({ name: 'IndexPage' })

const { adminPermissions } = storeToRefs(useAuthStore())
const { wlItems } = storeToRefs(useWhiteLabelStore())

const router = useRouter()

const { globalMenuList, wlMenuList } = useNavigationList()

watch(wlItems, val => {
  const path = adminPermissions.value.is_super_admin
    ? globalMenuList.value[0].defaultPath
    : wlMenuList.value[0].defaultPath

  if (!val.length || !path) return

  router.push(path)
})
</script>
