import { watch } from 'vue'

import useCurrencyStore from '~common/stores/currency'

import useWhiteLabel from './useWhiteLabel'

export default () => {
  const { activeWhiteLabel } = useWhiteLabel()

  const currencyStore = useCurrencyStore()

  watch(activeWhiteLabel, val => {
    currencyStore.fetch({ domain: val?.domain })
  })
}
