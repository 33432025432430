import { defineStore } from 'pinia'
import { ref } from 'vue'

import whiteLabelApi from '~/api/modules/whiteLabel.api'
import type { IWLItemLight } from '~/models/WhiteLabel'

import useWatchAuthenticated from '~common/composables/useWatchAuthenticated'

export default defineStore('white-label', () => {
  const wlItems = ref<IWLItemLight[]>([])

  const fetchWLItems = async () => {
    const res = await whiteLabelApi.fetchWhiteLabels()

    wlItems.value = res.data
  }

  useWatchAuthenticated(fetchWLItems)

  return {
    wlItems
  }
})
